import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import useSlug from '../hooks/useSlug';
import stripSlug from '../utils/stripSlug';
import ModuleList from "../modules/ModuleList";
import { navigate } from 'hookrouter';

function debounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments)
        }, ms)
    };
}

const Page = ({slug, content }) => {
    useSlug(decodeURI(slug));

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    React.useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });

        },500);

        window.addEventListener('resize', debouncedHandleResize);

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }});

    if (!content) return null;

    return (
        <Container>
            {
                {
                    'page' :  <ModuleList type='page' dimensions={dimensions} modules={content.modules} />,
                    'article' :  <ModuleList type='article' dimensions={dimensions} modules={content.modules} />,
                }[content._type] || navigate('/', true)
            }
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            content: state.pages[stripSlug(decodeURI(ownProps.slug))]
        },
        ownProps
    );
};

export default connect(mapStateToProps, null)(Page);


const Container = styled.div`
    width: 100%;

    &:after {
        content: '';
        clear: both;
        display: table;
    }

    > .module {
        margin-bottom: 80px;
    }
`;
